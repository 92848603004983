<template>
	<div id="payment_option" class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>MY 서비스</li>
							<li>결제 정보 관리</li>
						</ul>
					</div>
					<h2 class="page_title">결제 정보 관리</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="account_info">
						<template v-if="!isLogin()"><b>로그인이 필요한 서비스입니다</b></template>
						<template v-else><b>{{ getSessionProc().emailId }}</b> 님께서 신청한 서비스 이용 정보입니다.</template>
					</div>
					<div class="tbl_input">
						<table>
							<tr>
								<th>납부방법 선택 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" placeholder="신용카드 자동납부" disabled>
									</div>
									<p class="tbl_info">
										<b>신용카드 납부</b>만 가능하며, 추가 결제 수단이 등록되면 공지사항으로 안내드리겠습니다.
									</p>
								</td>
							</tr>
							<tr>
								<th>예금주 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" placeholder="이름(회사명)을 입력해 주세요." v-model="billInfo.userName">
									</div>
								</td>
							</tr>
							<tr>
								<th>전화번호 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" placeholder="전화번호를 입력해 주세요. (입력 시 -은 제외해 주세요.)" v-model="billInfo.phoneNumber">
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div class="payment_guide">
						<ul>
							<li>무료 이벤트 기간에는 결재되지 않으며 무료 이벤트 기간 종료 후 입력해주신 카드 정보로 결재됩니다.</li>
							<li>무료 이벤트 종료직전 1달전에 사전 공지되오니 원하지 않으시면 저희 wisenpure@eland.co.kr나 문의하기로 연락주세요.</li>
							<li>고객님의 본인 명의(미성년자의 경우 법정 대리인)의 국내 발행 신용카드 정보를 입력해주세요. (체크카드 인증 불가)</li>
							<li>신용카드 비밀번호 3회 이상 오류 시 해당 카드로 인증을 받으실 수 없으니 유의하시기 바랍니다.</li>
							<li>타인 명의의 계좌로 변경을 원하실 경우 고객센터로 문의해주십시요.</li>
						</ul>
					</div>

					<div class="button_area" v-if="isLogin()">
						<button type="button" class="btn_cancle btn_black" @click="goBack">취소</button>
						<button type="submit" class="btn_submit btn_primary" @click="reqInicis">신용카드 인증/변경</button>
						<button type="submit" class="btn_quit btn_red" @click="goQuit">회원 탈퇴</button>
					</div>

				</div>
			</div>

			<!-- 이니시스 결제시 전달할 정보 -->
			<form name="" id="SendPayForm_id" method="post" class="mt-5">
				<div class="row g-3 justify-content-between" style="--bs-gutter-x:0rem; display: none;">

					<!--label class="col-10 col-sm-2 gap-2 input param" style="border:none;">version</label>
					<label class="col-10 col-sm-9 input"-->
					<input type="hidden" name="version" value="1.0">
					<!--/label-->

					<label class="col-10 col-sm-2 input param" style="border:none;">gopaymethod</label>
					<label class="col-10 col-sm-9 input">
						<input type="text" name="gopaymethod" :value="pgConfig.gopaymethod">
					</label>

					<label class="col-10 col-sm-2 input param" style="border:none;">mid</label>
					<label class="col-10 col-sm-9 input">
						<input type="text" name="mid" :value="pgConfig.mid">
					</label>

					<label class="col-10 col-sm-2 input param" style="border:none;">oid</label>
					<label class="col-10 col-sm-9 input">
						<input type="text" name="oid" :value="billInfo.orderNumber">
					</label>

					<label class="col-10 col-sm-2 input param" style="border:none;">price</label>
					<label class="col-10 col-sm-9 input">
						<input type="text" name="price" :value="pgConfig.price">
					</label>

					<label class="col-10 col-sm-2 input param" style="border:none;">timestamp</label>
					<label class="col-10 col-sm-9 input">
						<input type="text" name="timestamp" :value="pgConfig.timestamp">
					</label>


					<input type="hidden" name="signature" :value="pgConfig.signature">
					<input type="hidden" name="mKey" :value="pgConfig.mKey">
					<input type="hidden" name="currency" :value="pgConfig.currency">


					<label class="col-10 col-sm-2 input param" style="border:none;">goodname</label>
					<label class="col-10 col-sm-9 input">
						<input type="text" name="goodname" value="신용카드 인증">
					</label>

					<label class="col-10 col-sm-2 input param" style="border:none;">buyername</label>
					<label class="col-10 col-sm-9 input">
						<input type="text" name="buyername" :value="billInfo.userName">
					</label>

					<label class="col-10 col-sm-2 input param" style="border:none;">buyertel</label>
					<label class="col-10 col-sm-9 input">
						<input type="text" name="buyertel" :value="billInfo.phoneNumber">
					</label>

					<label class="col-10 col-sm-2 input param" style="border:none;">buyeremail</label>
					<label class="col-10 col-sm-9 input">
						<input type="text" name="buyeremail" :value="billInfo.emailAddr">
					</label>

					<input type="hidden" name="returnUrl" :value="pgConfig.siteDomain + '/api/pg/service/returnForPaymentOption'">
					<input type="hidden" name="closeUrl" :value="pgConfig.siteDomain + pgConfig.closeUrl">
					<!--						<input type="hidden" name="returnUrl" value="https://[가맹점도메인]/INIbill_pc_return.jsp">-->
					<!--						<input type="hidden" name="closeUrl" value="https://[가맹점도메인]/close.jsp">-->

					<label class="col-10 col-sm-2 input param" style="border:none;">acceptmethod</label>
					<label class="col-10 col-sm-9 input">
						<input type="text" name="acceptmethod" value="HPP(1):below1000:va_receipt:BILLAUTH(Card)">
					</label>

				</div>
			</form>
			<!--  END 이니시스 결제시 전달할 정보 -->

		</div>
	</div>
</template>

<script>
import StringUtils from "../../commons/utils/string.utils";

export default {
	name: "paymentOption",
	data() {
		return {
			billInfo: {},
			pgConfig: {},

			resultCode : this.$route.query.resultCode,
			resultMsg : this.$route.query.resultMsg
		}
	},
	created() {
		//개발
		// this.$loadScript("https://stgstdpay.inicis.com/stdjs/INIStdPay.js")
		// 운영
		this.$loadScript("https://stdpay.inicis.com/stdjs/INIStdPay.js")
	},
	mounted() {
		const self = this;

		if(this.resultCode!=undefined && this.resultMsg !=undefined) {
			if(this.resultCode == "0000") {
				self.swalUtils.gritter(this.resultMsg, "", "success", 3000)
			} else {
				self.swalUtils.gritter(this.resultMsg, "", "warning", 3000)
			}
		}

		this.getMyBillInfo()
	},
	methods: {

		goQuit() {
			location.href = '/member/quit'
		},

		goBack() {
			history.back()
		},

		reqInicis() {
			if (!this.isLogin()) {
				return self.swalUtils.gritter("로그인이 필요합니다", "", "error", 3000)
			}

			if (!StringUtils.isNumber(this.billInfo.phoneNumber)) {
				return self.swalUtils.gritter("전화번호는 숫자만 입력해주세요", "", "error", 3000)
			}

			if (this.billInfo.userName == null || this.billInfo.phoneNumber == null) {
				return self.swalUtils.gritter("필수 입력란을 확인해주세요", "", "warning", 3000)
			}

			window.INIStdPay.pay('SendPayForm_id');
		},

		getPgConfig () {

			const self = this;

			this.axiosCaller.get(this, this.APIs.PG_SERVICE + "/getPgConfig", {'orderNum':self.billInfo.orderNumber}, (res) => {
				let result = res.data;
				if (result.status === "ok") {
					self.pgConfig = result.pgConfig;

					console.log(':::::::::::pgConfig',self.pgConfig)
				} else {
					this.swalUtils.gritter("Error", result.msg, "error")
							.then(() => {
								location.href = document.referrer
							})
				}
			});

		},

		getMyBillInfo() {
			const self = this;

			if( this.isLogin() ) {

				this.$eventBus.$emit("startProgress")

				var temp = JSON.parse(JSON.stringify(this.$route.params))
				temp.emailId = this.getSessionProc().emailId;

				this.axiosCaller.get(this, this.APIs.MY_SERVICE + "/getMyBillInfo", temp, (res) => {
					var result = res.data
					if (result.status === "ok") {
						self.billInfo = result.billInfo;

                        // 처음 가입한 사람 고려해서 해당부분 주석처리
						// if(self.billInfo==null) {
						// 	self.swalUtils.gritter("신청한 서비스가 존재하지 않습니다.", "서비스 신청 화면으로 이동합니다.", "info", 3000)
						// 			.then(() => {
						// 				location.href = '/service/info'
						// 			})
						// }

						// PG 세팅 가져오기
						self.getPgConfig()
					} else {
						this.swalUtils.gritter("Error", result.msg, "error")
					}
					this.$eventBus.$emit("doneProgress")
				})

			}

		}

	}
}
</script>

<style scoped>

</style>